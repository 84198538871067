.pagination {
  display: flex;
  @include list-unstyled();
  @include border-radius();
  -ms-flex-pack: center;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.page-link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  padding-top: 6px;
  width: 50px;
  height: 50px;
  font-size: 16px;
  font-family: 'Segou UI';
  margin-left: -$pagination-border-width;
  line-height: $pagination-line-height;
  color: $pagination-color;
  background-color: $pagination-bg;
  border: $pagination-border-width solid $pagination-border-color;

  &:hover {
    z-index: 2;
    color: $pagination-hover-color;
    text-decoration: none;
    //background-color: $pagination-hover-bg;
    //border-color: $pagination-hover-border-color;
  }

  &:focus {
    z-index: 2;
    outline: $pagination-focus-outline;
    box-shadow: $pagination-focus-box-shadow;
  }
}

.page-item {
  margin: 0 5px;
  &:first-child {
    .page-link {
      margin-left: 0;
      //@include border-left-radius($border-radius);
    }
  }
  &:last-child {
    .page-link {
      //@include border-right-radius($border-radius);
    }
  }

  &.active .page-link {
    //z-index: 1;
    color: $pagination-active-color;
    background-color: $pagination-active-bg;
    border-color: $pagination-active-border-color;
    //text-decoration: underline;
  }

  &.disabled .page-link {
    color: $pagination-disabled-color;
    pointer-events: none;
    // Opinionated: remove the "hand" cursor set previously for .page-link
    cursor: auto;
    background-color: $pagination-disabled-bg;
    border-color: $pagination-disabled-border-color;
    i::before{border-color: #aaa;}
  }

  &.arrow {margin: 0 15px;
    & + .arrow{margin-left: 0;}
  }
  &.arrow .page-link{
    border: 0;width: 30px;
    i{width: 30px;}
    i + i{margin-left: 0;}
  }
}


//
// Sizing
//

.pagination-lg {
  @include pagination-size($pagination-padding-y-lg, $pagination-padding-x-lg, $font-size-lg, $line-height-lg, $border-radius-lg);
}

.pagination-sm {
  @include pagination-size($pagination-padding-y-sm, $pagination-padding-x-sm, $font-size-sm, $line-height-sm, $border-radius-sm);
}

.page-arrow{
  padding: 0;margin: 0 60px;
  .page-link{padding: 0;}
}

.icon-page-prev{
  display: inline-block;width: 50px;height: 50px;position: relative;vertical-align: top;
  &::before{content: '';display: inline-block;width: 10px;height: 10px;border-left: 2px solid #000;border-top: 2px solid #000;transform: rotate(-45deg) translate(3px, 0);position: absolute;left: 50%;top: 50%;margin-top: -5px;margin-left: -5px;}
}

.icon-page-next{
  display: inline-block;width: 50px;height: 50px;position: relative;vertical-align: top;
  &::before{content: '';display: inline-block;width: 10px;height: 10px;border-right: 2px solid #000;border-bottom: 2px solid #000;transform: rotate(-45deg) translate(0, -2px);position: absolute;left: 50%;top: 50%;margin-top: -5px;margin-left: -5px;}
}

@include media-breakpoint-down(md) {
  .page-link {
    font-size: 15px;
  }
  .page-arrow{
    margin: 0 25px;
  }
  .icon-page-prev,
  .icon-page-next{
    width: 34px;height: 34px;
    &::before{width: 9px;height: 9px;margin-top: -4.5px;margin-left: -4.5px;}
  }
}