@font-face {
      font-family: 'Segoe UI';
      font-style: normal;
      font-weight: 400;
      src: url(../fonts/SegoeUI/SegoeUI.eot);
      src: url(../fonts/SegoeUI/SegoeUI.eot?#iefix) format('embedded-opentype'),
            url(../fonts/SegoeUI/SegoeUI.woff) format('woff'),
            url(../fonts/SegoeUI/SegoeUI.ttf) format('truetype');
  }
  @font-face {
      font-family: 'Segoe UI';
      font-style: normal;
      font-weight: 700;
      src: url(../fonts/SegoeUI/SegoeUI-Bold.eot);
      src: url(../fonts/SegoeUI/SegoeUI-Bold.eot?#iefix) format('embedded-opentype'),
            url(../fonts/SegoeUI/SegoeUI-Bold.woff) format('woff'),
            url(../fonts/SegoeUI/SegoeUI-Bold.ttf) format('truetype');
  }



@font-face {
      font-family: 'NanumBarunGothic';
      font-weight: 400;
      src: url(../fonts/NanumBarunGothic/NanumBarunGothicSubset.eot);
      src: url(../fonts/NanumBarunGothic/NanumBarunGothicSubset.eot?#iefix) format('embedded-opentype'),
            url(../fonts/NanumBarunGothic/NanumBarunGothicSubset.woff2) format('woff2'),
            url(../fonts/NanumBarunGothic/NanumBarunGothicSubset.woff) format('woff'),
            url(../fonts/NanumBarunGothic/NanumBarunGothicSubset.ttf) format('truetype');
}
@font-face {
      font-family: 'NanumBarunGothic';
      font-weight: 700;
      src: url(../fonts/NanumBarunGothic/NanumBarunGothicBoldSubset.eot);
      src: url(../fonts/NanumBarunGothic/NanumBarunGothicBoldSubset.eot?#iefix) format('embedded-opentype'),
            url(../fonts/NanumBarunGothic/NanumBarunGothicBoldSubset.woff2) format('woff2'),
            url(../fonts/NanumBarunGothic/NanumBarunGothicBoldSubset.woff) format('woff'),
            url(../fonts/NanumBarunGothic/NanumBarunGothicBoldSubset.ttf) format('truetype');
}
@font-face {
      font-family: 'NanumBarunGothic';
      font-weight: 300;
      src: url(../fonts/NanumBarunGothic/NanumBarunGothicLightSubset.eot);
      src: url(../fonts/NanumBarunGothic/NanumBarunGothicLightSubset.eot?#iefix) format('embedded-opentype'),
            url(../fonts/NanumBarunGothic/NanumBarunGothicLightSubset.woff2) format('woff2'),
            url(../fonts/NanumBarunGothic/NanumBarunGothicLightSubset.woff) format('woff'),
            url(../fonts/NanumBarunGothic/NanumBarunGothicLightSubset.ttf) format('truetype');
}
@font-face {
      font-family: 'NanumBarunGothic';
      font-weight: 200;
      src: url(../fonts/NanumBarunGothic/NanumBarunGothicUltraLightSubset.eot);
      src: url(../fonts/NanumBarunGothic/NanumBarunGothicUltraLightSubset.eot?#iefix) format('embedded-opentype'),
            url(../fonts/NanumBarunGothic/NanumBarunGothicUltraLightSubset.woff2) format('woff2'),
            url(../fonts/NanumBarunGothic/NanumBarunGothicUltraLightSubset.woff) format('woff'),
            url(../fonts/NanumBarunGothic/NanumBarunGothicUltraLightSubset.ttf) format('truetype');
}