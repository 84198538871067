// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

@if $enable-grid-classes {
  .container {
    @include make-container();
    @include make-container-max-widths();
  }
}

// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but with 100% width for
// fluid, full width layouts.

@if $enable-grid-classes {
  .container-fluid {
    @include make-container();
  }
}

// Row
//
// Rows contain and clear the floats of your columns.

@if $enable-grid-classes {
  .row {
    @include make-row();
  }

  // Remove the negative margin from default .row, then the horizontal padding
  // from all immediate children columns (to prevent runaway style inheritance).
  .no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

// Columns
//
// Common styles for small and large grid columns

@if $enable-grid-classes {
  @include make-grid-columns();
}


// custom row padding size
@mixin make-custom-row($name:12 ,$gutter-width:12) {
  .row-#{$name} {
    $_half:( $gutter-width / 2 );

    margin-left:- $_half; margin-right:- $_half;
    > [class^=col] {padding-left:$_half; padding-right:$_half;}
  }
}
@include make-custom-row(0, 0px);
@include make-custom-row(1, 1px);
@include make-custom-row(2, 2px);
@include make-custom-row(3, 3px);
@include make-custom-row(4, 4px);
@include make-custom-row(5, 5px);
@include make-custom-row(10, 10px);
@include make-custom-row(12, 12px);
@include make-custom-row(20, 20px);
@include make-custom-row(30, 30px);
@include make-custom-row(40, 40px);
@include make-custom-row(50, 50px);
@include make-custom-row(60, 60px);
@include make-custom-row(70, 70px);
@include make-custom-row(80, 80px);
@include make-custom-row(90, 90px);
@include make-custom-row(100, 100px);

/**
  px값 List를 만들어주는 함수입니다.
*/
@function size($start, $end) {
  $size : ();
  @for $i from $start through $end {
      $value : $i + 0;
      $size : append($size, $value);
  }
  @return $size;
}

/**
값이 0일때는 0으로, 그 외에는 'px'을 붙여줍니다.
ex) 0 => 0, 10 => 10px
*/
@function getPx($value) {
  @if $value == 0 {
      @return $value;
  } @else {
      @return $value + 0px;
  }
}

/**
px 값입니다.
0 ~ 100px 까지 조정하게 만들었습니다.
*/
$size : size(0, 100);

/**
margin과 padding의 각 위치를 지정해주는 map 입니다.
key는 class이름 생성시 쓰이며 value는 상세 속성 정의시 쓰입니다.
*/
$position : ('l':'left', 'r':'right', 't':'top', 'b':'bottom');

/**
margin과 padding 생성을 위한 map입니다.
key는 class이름 생성시 쓰이며 value는 상세 속성 정의시 쓰입니다.
*/
$nameMap : ('m-':'margin', 'p-':'padding');
/**
실제 css class를 만들어주는 mixin입니다.
nameMap, position, size를 혼합하여 아래와 같은 형식으로 만들어줍니다.

ex>
m-0 : { margin : 0; }
m-t-1 : { margin-top : 1px; }
*/
@mixin generate($nameMap : (), $position : (), $size : ()) {
  @each $preKey, $preValue in $nameMap {
      @each $px in $size {
          .#{$preKey}-#{$px} {
              #{$preValue} : getPx($px);
          }
          @each $sufKey, $sufValue in $position {
              .#{$preKey}#{$sufKey}-#{$px} {
                  #{$preValue}-#{$sufValue} : getPx($px);
              }
          }
      }
  }
}
@include generate($nameMap, $position, 5);
@include generate($nameMap, $position, 10);
@include generate($nameMap, $position, 15);
@include generate($nameMap, $position, 20);
@include generate($nameMap, $position, 25);
@include generate($nameMap, $position, 30);
@include generate($nameMap, $position, 35);
@include generate($nameMap, $position, 40);
@include generate($nameMap, $position, 45);
@include generate($nameMap, $position, 50);